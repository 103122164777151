import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundSlider from "gatsby-image-background-slider"

const BackgroundSlideShow = () => {
  return (
    <div id="bsg">
      <BackgroundSlider
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { relativeDirectory: { eq: "background" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        `)}
        initDelay={2} // delay before the first transition (if left at 0, the first image will be skipped initially)
        transition={4} // transition duration between images
        duration={8}
        style={{ animation: "bg 45s linear infinite" }}
      />
    </div>
  )
}
export default BackgroundSlideShow