import React from "react"
import SocialIcons from "../Common/socialIcons"
import { Link } from "gatsby"
export default function Footer() {
  return (
    <footer className="footer position">
      <div className="content has-text-centered">
      <div className="footer-color-position">
        <SocialIcons />
            <a href="https://tageoforce.com" aria-label="T.A. Geoforce" target="_blank" rel="noopener noreferrer"
            >
              &copy; T.A. Geoforce
              </a>{" "}|{" "}
            <Link to="/terms">Terms &amp; Conditions</Link>
              {" "}|{" "}
            <Link to="/privacy">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  )
}
