/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import config from "../../../config"
import IconLogo from "../Icons/Logo"
import Background from "./Background"
import BackgroundSlideShow from "./BackgroundSlideShow"

export default function Hero() {
  return (
    <section className="hero is-fullheight-with-navbar">
      <span id="top">{" "}</span>
      <div className="hero-body">
        <div className="container has-text-centered PositionOverParticlesHero">
          <div className="headerLogo_hero">
            <div className="logo_hero">
              <IconLogo />
            </div>
            </div>
            <h1>
              {config.heading}
            </h1>
            <p className="sizeHero">
              {config.subHeading}
            </p>
        </div>
      </div>
      <BackgroundSlideShow />
      <Background />
    </section>
  )
}
