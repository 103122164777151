/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Main/Footer"
import ContactForm from "../components/Main/ContactForm"
import Hero from "../components/Main/Hero"
import Cookies from "../components/Main/Cookies"

const IndexPage = () => {
  return (
    <Layout location="/" titlePage="Home">
        <div className="inside-scrollbar">
          <Hero />
          <div className="container has-text-centered">
            <section className="section">
              <h2>Contact us</h2>
              <ContactForm />
            </section>
          </div>
          <Footer />
        </div>
        <Cookies />
         <a className="hideText" href="#top">
           Press here to go to the top of the site.
          <i className="scrollup fa fa-chevron-up"></i>
         </a> 
    </Layout>
  )
}
export default IndexPage