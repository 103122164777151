import React, { useEffect, useState } from "react"
const Cookies = () => {
  const [cookiesPanel, setCookiesPanel] = useState({
    useCookies: true,
    visible: true,
  })

  useEffect(() => {
    window[`ga-disable-${process.env.GOOGLE_ANALYTICS_KEY}`] =
      cookiesPanel.useCookies
  }, [cookiesPanel])

  return (
    <>
      {typeof window !== `undefined` ? (
        !document.cookie.includes("poles_on_earth_cookie") && (
          <div className="notification is-primary position"> 
              We use cookies to provide and improve our services. By using our
              site, you consent to cookies.
            <span />
              <span className="allbtns">
                <button
                  className="accept"
                  onClick={() => {
                    setCookiesPanel({ useCookies: true, visible: false })
                    document.cookie = "poles_on_earth_cookie=true"
                  }}
                >
                  Accept
              </button>
                <button
                  className="decline"
                  onClick={() => {
                    setCookiesPanel({ useCookies: false, visible: false })
                    document.cookie = "poles_on_earth_cookie=false"
                  }}
                >
                  Decline
              </button>
              </span>
           
          </div>
        )) : null}
    </>
  )
}

export default Cookies
